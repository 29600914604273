<template>
    <div class="teachingResearch wrap1148">
        <div class="tit-teachingResearch">
            <p>动态</p>
            <ul>
                <li v-for="item in moduleIdList"
                    :key="item.id"
                    @click="moduleIdListPick(item)"
                    :class="{'active': item.id===moduleId}"   
                >{{item.name}}</li>
                <!-- <li class="active">全部动态</li>
                <li>资讯</li>
                <li>活动</li>
                <li>课题</li> -->
            </ul>
        </div>
        <ul class="list-row">
            <li v-for="(item,index) in contentList">
                <a href="javascript:;" class="li-left" @click="linkToDetails(item)">
                    <img :src="'https://oss.eyyb.vip/'+item.bgkImg" alt="" width="282" height="158">
                </a>
                <div class="list-infos">
                    <h5><a href="javascript:;" @click="linkToDetails(item)">{{item.name}}</a></h5>
                    <div class="size-16 posra">
                        <p><i class="s-tips s-tips-blue" v-if="item.labels">{{item.labels}} </i> <span>{{item.resourceFrom}} | {{item.publishDate ? item.publishDate : '2020-02-20'}}</span> <i>{{moduleName}}</i></p>
                        <!-- <p><i class="s-tips s-tips-blue">资讯</i> <span>语文报 | 2019-01-01</span> <i>教材解读</i></p> -->
                        <i class="el-icon-view"> {{item.playTimes}}次</i>
                    </div>
                </div>
            </li>
            <!-- <li>
                <a href="#" class="li-left"><img src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" alt="" width="282" height="158"></a>
                <div class="list-infos">
                    <h5><a href="#">关注要素发展，兼顾工具人文，走进语文学习胡百花深处</a></h5>
                    <div class="size-16 posra">
                        <p><i class="s-tips">活动</i> <span>语文报 | 2019-01-01</span> <i>教材解读</i></p>
                        <i class="el-icon-view"> 1561次</i>
                    </div>
                </div>
            </li>
            <li>
                <a href="#" class="li-left"><img src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" alt="" width="282" height="158"></a>
                <div class="list-infos">
                    <h5><a href="#">关注要素发展，兼顾工具人文，走进语文学习胡百花深处</a></h5>
                    <div class="size-16 posra">
                        <p><i class="s-tips s-tips-orange">课题</i> <span>语文报 | 2019-01-01</span> <i>教材解读</i></p>
                        <i class="el-icon-view"> 1561次</i>
                    </div>
                </div>
            </li> -->
        </ul>
        <div class="list-pagination">
            <el-pagination
                layout="prev, pager, next"
                @current-change="handleCurrentChange"
                :current-page="pageInfo.pageNum"
                :page-size="1"
                :total="pageInfo.pages">
            </el-pagination>
        </div>
    </div>
</template>

<style lang="scss" scope>
    @import '@/assets/css/pages/teachingResearch.scss';
    
</style>

<script>
    import {mapGetters} from "vuex";
    export default {
        data () {
            return {
                modulesId: '', // 大主题id
                moduleIdList: [], // 系列列表
                moduleId: '', // 系列列表当前选中
                moduleName: '', // 系列列表当前选中name
                contentList: [], // 系列下列表
                pageInfo: {pages: 1, pageNum: 1} // 分页
            }
        },
        created () {
            this.modulesId = this.$route.query.moduleId ? this.$route.query.moduleId : this.$route.meta.moduleId;
            this.moduleId = this.$route.query.itemId ? this.$route.query.itemId : '';
            this.loadModels();
        },
        methods: {
            loadModels() { // 加载主题
                this.$http({
                    method: 'get',
                    // //api/yyb/v1/expModule/web/querySeriesByModuleId
                    // //api/yyb/v1/expModule/web/queryModuleByParentId
                    url: '/api/yyb/v1/expModule/web/queryModuleByParentId',
                    params: {
                        moduleId: this.modulesId
					}
                }).then(res => {
                    console.log('res', res)
                    this.moduleIdList =  res.data.obj;
                    if(this.$route.query.itemName) {
                        this.moduleIdListPick({id: this.moduleId, name: this.$route.query.itemName});
                    } else {
                        this.moduleIdListPick(this.moduleIdList[0]);
                    }
                    // this.moduleId = this.moduleIdList[0].id;
                    // this.moduleIdListPick(this.moduleIdList[0]);
                }).catch(error => {
                    console.log('error', error)
                })
            },
            handleCurrentChange(val) { // 页码选择
                this.pageInfo.pageNum = val;
                this.loadPickList(this.pageInfo.pageNum);
            },
            moduleIdListPick(row) { // 模块选择
                this.moduleId = row.id;
                this.moduleName = row.name;
                this.pageInfo.pageNum = 1;
                this.loadPickList(1);
            },
            loadPickList(pageNum) { // 加载列表内容
                this.$http({
                    method: 'get',
                    url: '/api/yyb/v1/expModule/web/queryEntryPage',
                    params: {
                        moduleId: this.modulesId,
                        themeId: this.moduleId,
                        pageNo: pageNum,
                        pageSize: 6
					}
                }).then(res => {
                    console.log('res', parseInt(res.data.obj.articlePage.pages));
                    this.contentList = res.data.obj.articlePage.list;
                    this.pageInfo = {
                        pages: parseInt(res.data.obj.articlePage.pages)
                    }
                }).catch(error => {
                    console.log('error', error)
                })
            },
            linkToDetails(item) {
                this.$router.push({ 
                    name: 'trendsDetails',
                    params: {
                        id: item.id
                    },
                    query:{
                        name: `${item.name}`, 
                        themeId: this.moduleId, 
                        moduleId: this.modulesId
                    }
                });
            }
        }
    }
</script>